/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cloudflare 장애"), "\n", React.createElement(_components.li, null, "무슨 일이 있었는가? https://twitter.com/kid1ng/status/1720728259065971192?s=46&t=de4hPka0wN3uWpcqe0uh6g"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/post-mortem-on-cloudflare-control-plane-and-analytics-outage/?utm_campaign=cf_blog&utm_source=twitter&utm_medium=organic_social&utm_content=20231104"
  }, "Cloudflare 대표의 포스트모텀"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1693"
  }, "아싸님의 요약글")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "레이첼 님이 겪은 일과 Cloudflare + 파트너사의 대응", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "장애 인지", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "한국 시간으로 11/2 저녁 9시 즈음 파트너사 담당자 분이 전화로 Cloudflare 장애 알림"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "장애 전파", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cloudflare Stream을 사용하는 3개의 주요 팀에 알림"), "\n", React.createElement(_components.li, null, "그 중, 한 팀은 서비스 런칭 하루 만에 장애를 겪음"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "장애 여파", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "새롭게 런칭된 서비스는 하루 만에 서비스 내림"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "장애 대응", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "100% Cloudflare 의존적인 상황이라 할 수 있는게 없었음"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "레슨런", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "100% Cloudflare 의존적인 상황을 점진적으로 줄여나가는 방법 고민"), "\n"), "\n"), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
